import "@/styles/globals.css";
import { MantineProvider } from "@mantine/core";
import { AppProps } from "next/app";
import { DM_Sans } from "next/font/google";
import Head from "next/head";

const dmSans = DM_Sans({
  subsets: ["latin"],
  weight: "400",
});
export default function App({ Component, pageProps }: AppProps) {
  return (
    <main className={dmSans.className}>
      <Head>
        <title>Gabriella Martins</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="shortcut icon" href="/logo_icon.png" />
      </Head>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: "light",
        }}
      >
        <Component {...pageProps} />
      </MantineProvider>
    </main>
  );
}
